<template>
  <v-container>
    <my-toolbar-progress-bar
      :isLoading="isLoading"
      :color="toolbareColor"
      slot="extension"
    />
    <v-data-table
      :headers="headers"
      :items="dataList"
      class="elevation-1"
      group-by="FirmaNavn"
      sort-by="Navn"
      @click:row="rowClick"
      item-key="BrukerID"
      single-select
    >
      <template #item.TokenTimeOut="{ item }">
        <span>{{ formatDate(item.TokenTimeOut) }}</span>
      </template>
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Gruppe - {{ currentHeading }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogSelect" max-width="500px">
            <template #activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Ny</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <dlgSelectTeam v-if="dialogSelect" v-model="itemsRolle" />
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeSelect"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="saveSelect"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogRolle" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <dlgRolle
                      v-if="dialogRolle"
                      v-model="editedItem"
                      :editMode="editedIndex"
                    />
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeRolle"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="saveRolle"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";
import MyToolbarProgressBar from "@/components/MyToolbarProgressBar.vue";

export default {
  extends: KeyValue,
  components: {
    dlgSelectTeam: () => import("../dialog/dlgSelectTeam"),
    dlgRolle: () => import("../dialog/dlgRolle"),
    MyToolbarProgressBar,
  },
  data() {
    return {
      itemsRolle: [],
      isLoading: false,
      toolbareColor: "red",
      keyProsjektID: -1,
      dialogSelect: false,
      dialogRolle: false,
      headers: [
        { text: "Firma", value: "FirmaNavn" },
        { text: "Navn", value: "Navn" },
        { text: "Tlf 1", value: "Tlf1" },
        { text: "E-Post 1", value: "eMail1" },
        { text: "Rolle beskrivelse", value: "ProsjektRolleID" },
        { text: "Aksjon", value: "actions", sortable: false },
      ],
      currentBrukerID: -1,
      currentHeading: "",
      dataList: [],
      editedIndex: -1,
      editedItem: {
        ProsjektID: "",
        BrukerID: "",
        ProsjektRolleID: "",
      },
      defaultItem: {
        ProsjektID: "",
        BrukerID: "",
        ProsjektRolleID: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny" : "Endre";
    },
  },

  watch: {
    dialogRolle(val) {
      val || this.closeRolle();
    },
    dialogSelect(val) {
      val || this.closeSelect();
    },
  },

  created() {
    this.keyProsjektID = this.$route.params.ProsjektID;
    this.initialize();
  },
  methods: {
    rowClick: function (item, row) {
      row.select(true);
      //this.editedIndex = this.dataList.indexOf(item);
      this.currentBrukerID = item.BrukerID;
      this.currentHeading = item.Navn;
      //this.$root.$emit("Team", item.BrukerID, item.Navn);
    },
    initEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    initialize() {
      this.isLoading = true;
      this.GetProsjektRolleCode();
      this.GetTeamData(this.keyProsjektID);
      this.initEditItem();
      this.isLoading = false;
    },

    FyllRolleList() {
      this.itemsRolle = [];
      let i = 0;
      for (i = 0; i < this.dataList.length; i++) {
        const a = new Object();
        a.id = "C" + this.dataList[i].BrukerID;
        a.name = this.dataList[i].Navn;
        this.itemsRolle.push(a);
      }
    },

    GetTeamData(keyProsjektID) {
      axios({
        method: "get",
        params: { ProsjektID: keyProsjektID },
        url: DataSourceURL + "/Team/GetData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.dataList = res.data.DataList;
            this.FyllRolleList();
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /Team/GetData. See details: ${err}`
          );
        });
    },

    editItem(item) {
      this.editedIndex = this.dataList.indexOf(item);
      this.editedItem.ProsjektID = this.keyProsjektID;
      this.editedItem.BrukerID = item.BrukerID;
      this.editedItem.ProsjektRolleID = this.FindCode(
        item.ProsjektRolleID,
        this.ProsjektRolleItems
      );
      this.dialogRolle = true;
    },

    DeletedataList(index, ProsjektID, BrukerID) {
      axios({
        method: "delete",
        url: DataSourceURL + "/Rolle/DoDelete",
        params: { ProsjektID: ProsjektID, BrukerID: BrukerID },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "DELETE",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.dataList.splice(index, 1);
            this.FyllRolleList();
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Rolle/DoDelete. See details: ${err}`
          );
        });
    },

    deleteItem(item) {
      const index = this.dataList.indexOf(item);
      if (
        confirm("Are you sure you want to delete this item? " + item.Navn) ==
        true
      ) {
        this.DeletedataList(index, this.keyProsjektID, item.BrukerID);
      }
    },

    closeRolle() {
      this.dialogRolle = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },

    closeSelect() {
      this.dialogSelect = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },

    DoSaveSelect() {
      const myData = [];
      let i = 0;
      for (i = 0; i < this.itemsRolle.length; i++) {
        if (String(this.itemsRolle[i].id).slice(0, 1) == "C") {
          const a = new Object();
          a.ProsjektID = this.keyProsjektID;
          a.BrukerID = String(this.itemsRolle[i].id).slice(1);
          a.ProsjektRolleID = 2;
          myData.push(a);
        }
      }

      axios({
        method: "post",
        url: DataSourceURL + "/Team/DoSaveSelect",
        data: { data: myData },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.closeSelect();
            this.GetTeamData(this.keyProsjektID); //Får laste inn data på ny
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in saving /Team/DoSaveSelect. See details: ${err}`
          );
        });
    },

    saveSelect() {
      this.DoSaveSelect();
    },

    DoSaveRolle() {
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editedIndex > -1) {
        myUrl = DataSourceURL + "/Rolle/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Rolle/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: this.editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK

              //Ordre kode felt
              //this.editedItem.ProsjektRolleID = this.FindDescription(this.editedItem.ProsjektRolleID, this.ProsjektRolleItems);
              if (this.editedIndex > -1) {
                //alert(this.editedIndex + " - " + this.editedItem.ProsjektRolleID + " " + this.ProsjektRolleItems.length);
                this.dataList[
                  this.editedIndex
                ].ProsjektRolleID = this.FindDescription(
                  this.editedItem.ProsjektRolleID,
                  this.ProsjektRolleItems
                );
                //Object.assign(this.dataList[this.editedIndex], this.editedItem); //Oppdatering
              } else {
                //this.dataList.push(this.editedItem);  //Ny
              }
              this.closeRolle();
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    saveRolle() {
      this.DoSaveRolle();
    },
  },
};
</script>
